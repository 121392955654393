import en_messages from './en'
import es_messages from './es'
import de_messages from './de'
import ru_messages from './ru'
import ro_messages from './ro'

import '@formatjs/intl-relativetimeformat/polyfill'
import '@formatjs/intl-relativetimeformat/dist/locale-data/es'
import '@formatjs/intl-relativetimeformat/dist/locale-data/de'
import '@formatjs/intl-relativetimeformat/dist/locale-data/en'
import '@formatjs/intl-relativetimeformat/dist/locale-data/ru'
import '@formatjs/intl-relativetimeformat/dist/locale-data/ro'


const locales = [
  {
    locale: 'en',
    messages: en_messages
  },
  {
    locale: 'es',
    messages: es_messages
  },
  {
    locale: 'de',
    messages: de_messages
  },
  {
    locale: 'ru',
    messages: ru_messages
  },
  {
    locale: 'ro',
    messages: ro_messages
  }
]

export default locales
