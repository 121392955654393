import { defineMessages } from 'react-intl';

const messages = defineMessages({
  app_name: 'Escorte⁺',
  dashboard: 'Tabla ',
  about_label: 'Despre noi',
  home_label: 'Acasa',
  _label: 'N/A',
  '404': '404',
  warning_404:'Găsiți escorte în orașul tău.',
  warning_404_title:'Anunturi escorte',
  warning_404_message: 'Eroare 404!',
  warning_404_description: 'Pagina nu a fost găsită',
  settings: 'Configuratii',
  search_settings: 'Setări de căutare',
  language: 'Limba',
  languages_label:'Limbi',
  theme: 'Temă',
  notifications: 'Notificari',
  email_notifications: 'Notificari email',
  yes_label: 'Da',
  no_label: 'Nu',
  policy_label: 'Politica de confidentialitate',
  terms_label: 'Termeni de Utilizare',
  legal:'Legale',
  legal_la:  ' Confidențialitate și termeni',
  legal_label: 'Prin procedură, confirmați că aveți peste 18 ani și ați citit, înțeles și sunteți de acord cu ',
  piercings_label: 'Piercing-uri',
  sugar_label: 'Vreau un Sugar Daddy',
  sugar_hint: 'Asteptari (i.e. 9000€/luna)',
  daddy_label: 'Sugar Baby dorinte',
  responsive: 'Receptiv',
  phone_hint: 'Numărul De Telefon',
  show_tel: 'Arată numărul',
  en: 'English',
  de: 'Deutsch',
  ru: 'Русский',
  es: 'Español',
  it: 'Italiano',
  ro: 'Română',
  dark: 'Intunecat',
  blueGrey: 'Gri',
  night_mode: 'Întunecată',
  day_mode: 'Luminoasă',
  sign_out: 'Deconectare',
  sign_in: 'Conectare',
  sign_up: 'Inscriete',
  found: 'Noi am găsit',
  girls_la: 'fetele în aria asta',
  girl_la: 'fată în aria asta',
  join_us: 'Fa-ti publicitate aici',
  sign_in_with_google: 'Conecteazate cu Google',
  sign_in_with_twitter: 'Conecteazate cu Twitter',
  'link_with_google.com': 'Link cu Google',
  'link_with_twitter.com': 'Link cu Twitter',
  my_account: 'Contul meu',
  wname: 'Numele escortei',
  email: 'Email',
  password: 'Parola',
  new_password: 'Parola noua',
  confirm_password: 'Confirma parola',
  forgort_password: 'Ai uitat parola?',
  reset_password: 'Reseteaza parola',
  change_password: 'Schimba parola',
  change_photo: 'Schimba poza',
  change_email: 'Schimba email-ul',
  reset_password_hint: 'Introduceti adresa de email',
  save: 'Salvează',
  delete_account: 'Șterge contul',
  drop_or_select_file_label:'Picătură sau Răsfoiți fișierele',
  select_file: 'Selectează fișierul',
  cancel: 'Aanulează',
  submit: 'Încărcați',
  delete: 'Ștergere',
  ok: 'OK',
  max_file_size: 'Marimea maxima a imaginii este 5MB!',
  delete_account_dialog_title: 'Ștergere cont?',
  delete_account_dialog_message: 'Contul tau va fi sters si vei pierde toate datele!',
  email_not_verified: 'Email-ul nu este verificat!',
  email_verified: 'Email-ul este verificat',
  send_verification_email: 'Trimite email de verificare',
  send_verification_email_again: 'Trimite email de verificare din nou',
  delete_role_dialog_message:'This Role will be deleted',
  delete_role_dialog_title:'Delete this role?',
  users: 'Utilizatori',
  edit: 'Modifică',
  online: 'Online',
  offline: 'Offline',
  no_connection_warning: 'Lipsa conectie!',
  undefined_label: ' N/A ',
  undefined_la: ' N/A ',
  no_connection: 'Lipsa conectie',
  error: 'Eroare!',
  profiles: 'Profile',
  gallery_label: 'Escorte lângă mine',
  create_profile: 'Creează profil',
  edit_profile: 'Modifică Profilul',
  delete_profile_title: 'Șterge acest profil?',
  delete_profile_message: 'Profilul escortei va fi sters!',
  error_required_field: 'Trebuie completat',
  error_geo: 'Asigură-te că serviciile de localizare sunt activate',
  error_age_field: 'Copiii nu au voie!',
  smoking_label: 'Fumător',
  occasionally_label: 'Ocazional',
  dress: 'Mărimea rochiei',
  age_label: 'Vârsta',
  age_hint: 'Introduceți vârsta',
  breast_label: 'Mărimea sânilor',
  breast_hint: 'Introduceți mărimea sânilor',
  eyes_label: 'Culoarea ochilor',
  service_label: 'Selectați serviciu',
  service: 'Disponibilitate',
  available:'Disponibilă chiar acum!',
  undefined_inla: ' ',
  undefined_outla: ' ',
  true_inla: 'La escorta',
  true_outla: 'La client',
  false_outla: ' ',
  false_inla: ' ',
  outcall_label: 'La Client',
  outcall_la: 'La Client',
  incall_label: 'La Escorta',
  incall_la: 'La Escorta',
  height_hint: 'Introduceți înălțimea',
  height_label: 'Înălțime',
  weight_label: 'Greutate',
  weight_hint: 'Completați greutatea',
  amber_label: 'Chihlimbar',
  green_label: 'Verde',
  default: 'Implicit',
  green: 'Verzi',
  purple_label: 'Mov',
  blue_label: 'Albastri',
  gray_label: 'Gri',
  hazel_label: 'Caprui ',
  hair_label: 'Culoarea parului',
  red_label: 'Roscata',
  grey: 'Argintiu',
  blonde_label: 'Blonda',
  brunette_label: 'Bruneta',
  black_label: 'Negru',
  brown_label: 'Maro',
  other_label: 'Altă',
  nationality_label: 'Naționalitate',
  al_la: 'Albaneză',
  ar_la: 'Argentiniană',
  dz_la: 'Algeriană',
  am_la: 'Armeniană',
  au_la: 'Australiană',
  at_la: 'Austriacă',
  az_la: 'Azeră',
  bs_la: 'Bosniacă',
  by_la: 'Bielorusă',
  be_la: 'Belgiană',
  bo_la: 'Boliviană',
  br_la: 'Braziliană',
  bg_la: 'Bulgară',
  cm_la: 'Cameruneză',
  cat_la: 'Catalană',
  ca_la: 'Canadiană',
  cl_la: 'Chiliană',
  cn_la: 'Chineză',
  co_la: 'Columbiană',
  cr_la: 'Costa Rica',
  hr_la: 'Croată',
  cu_la: 'Cubaneză',
  cz_la: 'Cehă',
  dk_la: 'Daneză',
  do_la: 'Dominicană',
  ec_la: 'Ecuadoriană',
  eg_la: 'Egiptană',
  ee_la: 'Estoniană',
  fi_la: 'Finlandeză',
  fr_la: 'Franceză',
  ge_la: 'Georgiană',
  de_la: 'Germană',
  gr_la: 'Greacă',
  gb_la: 'Britanică',
  en_la: 'Engleză',
  sct_la: 'Scotiană',
  ht_la: 'Haitiană',
  he_la: 'Israeliană',
  hi_la: 'Hindusă',
  hn_la: 'Honduriană',
  hu_la: 'Maghiară',
  in_la: 'Indiană',
  is_la: 'Islandeză',
  ga_la: 'Irlandeză',
  id_la: 'Indoneziană',
  il_la: 'Israeliană',
  it_la: 'Italiană',
  jm_la: 'Jamaicană',
  jp_la: 'Japoneză',
  kr_la: 'Coreeană',
  kz_la: 'Kazahstană',
  lv_la: 'Letonă',
  lt_la: 'Lituaniană',
  my_la: 'Malaeziană',
  mk_la: 'Macedonă',
  mx_la: 'Mexicană',
  md_la: 'Moldovana',
  ma_la: 'Marocană',
  nl_la: 'Olandeză',
  ni_la: 'Nicaraguană',
  ng_la: 'Nigeriană',
  no_la: 'Norvegiană',
  pk_la: 'Pachistaneză',
  py_la: 'Paraguiană',
  fa_la: 'Persian',
  pl_la: 'Poloneză',
  pt_la: 'Portugheză',
  ro_la: 'Română',
  ru_la: 'Rusă',
  rs_la: 'Sarbă',
  sk_la: 'Slovacă',
  si_la: 'Slovenă',
  es_la: 'Spaniolă',
  se_la: 'Suedeză',
  ch_la: 'Elvetiancă',
  th_la: 'Tailandeză',
  tn_la: 'Tunisiană',
  tr_la: 'Turcă',
  ua_la: 'Ucrainiană',
  us_la: 'Americană',
  uz_la: 'Uzbekistană',
  cy_la: 'Galeză',
  ve_la: 'Venezuelean',
  vn_la: 'Vietnameză',
  orientation_label: 'Orientare',
  straight_label: 'Heterosexuală',
  bicurious_label: 'Bi-curiozitatea',
  bisexual_label: 'Bisexuală',
  description_label: 'Descriptie',
  description_hint: 'Introduceti o descriere sumara',
  name_label: 'Nume',
  name_hint: 'Introduceti numele',
  details_label: 'Detalii',
  details_la: 'Detalii',
  details:'Actualizați locația',
  contact_label:'Contact Details',
  info_label: 'Info',
  rates_label: 'Tarife',
  rates: 'Rates from',
  distance: 'Distanta (Km) ',
  miles_away: ' km distanță',
  onehour_label:'Prima oră',
  price_hint: 'Introduceţi pretul (i.e. 100€)',
  addhour_label:'Oră extra',
  overnight_label: 'Noapte',
  twentyfourhour_label: '24 ore',
  threedays_label: '3 zile',
  week_label: 'Săptămâna',
  phone_label: 'Nr de telefon',
  website_hint: 'i.e. www.exemplu.com',
  website_label: 'Site-ul personal',
  value_label: 'Valoare',
  public_chats: 'Chat public',
  delete_message_title: 'Şterge mesaj?',
  delete_message_message: 'Mesajul va fi sters!',
  users_count_title: '{number} Users',
  user_registrationg_graph_label: 'User registrations',
  required: 'Necesar',
  twitter: 'Twitter',
  phone: 'Phone: ',
  google: 'Google',
  favourites: 'Favorite',
  favorites_description: 'Nu aveti favorite!',
  twitter_color: '#36A2EB',
  phone_color: '#90A4AE',
  google_color: '#EA4335',
  password_color: '#4CAF50',
  chats: 'Chats',
  write_message_hint: 'Scrie un mesaj...',
  load_more_label: 'Mai mult...',
  my_location: 'Locația mea',
  location_label: 'Actualizați locația',
  select_user: 'Alege utilizator',
  operator_like_label: 'Imi place',
  operator_notlike_label: 'Nu imi place',
  operator_equal_label: 'equal',
  operator_notequal_label: 'nu este egal',
  operator_novalue_label: 'fara valoare',
  administration: 'Admin',
  roles: 'Roles',
  grants: 'Grants',
  private: 'Privat',
  public: 'Public',
  time_label:'Timp',
  grant_read_profiles: 'Read profiles',
  grant_create_profile: 'Create escort',
  grant_edit_profile: 'Edit escort',
  grant_delete_profile: 'Delete escort',
  is_admin_label: 'Administrator',
  select_field: 'Select field',
  sorting: 'Sortare',
  filters: 'Filtre',
  filter: 'Filtru',
  add_filter: 'Adauga filtru',
  delete_filter: 'Sterge filtru',
  mark_chat_as_unread: 'Marchează ca necitit',
  delete_chat: 'Ștergeți chatul',
  change_sort_orientation: 'Schimbați orientarea',
  enable_case_sensitivity: 'Caz sensibil',
  disable_case_sensitivity: 'Caz insensibil',
  hint_autocomplete: 'Selecteaza',
  enter_query_text: 'Intoduceti textul',
  email_label: 'Email',
  close_filter: 'Inchide filtrul',
  close: 'Inchide',
  open_filter: 'Deschide filtru',
  select_operator: 'Selecteaza operator',
  operator_contains_label: 'Operator',
  not_match_found: 'Nu am gasit nimic',
  edit_user: 'Modifica utilizator',
  edit_role: 'Modifica rolul',
  firestore: 'Firestore',
  ads:'Anunturi gratuite',
  ads_description:'Ajunge la mai mulți clienți! Câștigați mai mulți bani!',
  no_thanks: 'Nu, mulţumesc!',
  post:'Adauga un anunt!',
  user_label_search: 'Căutaţi',
  install: 'Instala',
  ios_prompt_title: 'Adăugați la ecranul principal',
  ios_prompt_close: 'Închideți',
  ios_prompt_body:
    'Escorte⁺ are funcționalitatea aplicației. Adăugați-o pe ecranul de pornire pentru a rula ca orice altă aplicație instalată. Escorte⁺ nu trebuie descărcată.',
  ios_prompt_share_button: "1) Apăsați butonul „Share”",
  ios_prompt_add_to_home_button: "2) Apăsați „Adăugați la ecranul principal”",
});

export default messages;
