import { defineMessages } from 'react-intl';

const messages = defineMessages({
  app_name: 'Escorts⁺',
  dashboard: 'Dashboard',
  home_label: 'Home',
  _label: 'N/A',
  '404': '404',
  warning_404:'Find the best escorts in your local area',
  warning_404_title:'Escort Classifieds',
  warning_404_message: 'Nothing Found!',
  warning_404_description: 'Unfortunately, nothing was found at this location.',
  settings: 'Settings',
  search_settings: 'Search Settings',
  language: 'Language',
  languages_label:'Languages',
  theme: 'Theme',
  email_notifications: 'Email notifications',
  yes_label: 'Yes',
  no_label: 'No',
  policy_label: 'Privacy Policy',
  terms_label: 'Terms & Conditions',
  legal:'Legal',
  legal_la:  ' The Privacy & Terms',
  legal_label: 'By proceeding you are confirming that you are over 18 years old and you have read, understood, and agree to ',
  piercings_label: 'Piercings',
  sugar_label: 'I want a Sugar Daddy',
  sugar_hint: 'Expectation (i.e. £9000/monthly)',
  daddy_label: 'Sugar Baby Expectation',
  responsive: 'Responsive',
  phone_hint: 'Contact Phone Number',
  show_tel: 'Show number',
  en: 'English',
  de: 'Deutsch',
  ru: 'Русский',
  es: 'Español',
  it: 'Italiano',
  ro: 'Română',
  dark: 'Dark',
  blueGrey: 'Grey',
  light: 'Light',
  ics: 'ICS',
  sign_out: 'Sign out',
  sign_in: 'Sign in',
  sign_up: 'Sign up',
  found: 'We found',
  girls_la: 'girls in your area',
  girl_la: 'girl in your area',
  join_us: 'Advertise here',
  sign_in_with_google: 'Sign in with Google',
  sign_in_with_twitter: 'Sign in with Twitter',
  'link_with_google.com': 'Link with Google',
  'link_with_twitter.com': 'Link with Twitter',
  my_account: 'My account',
  wname: 'Escort Name',
  email: 'E-Mail',
  password: 'Password',
  new_password: 'New Password',
  confirm_password: 'Confirm Password',
  forgort_password: 'Forgot Password?',
  reset_password: 'Reset Password',
  change_password: 'Change Password',
  change_photo: 'Change Photo',
  change_email: 'Change E-Mail',
  reset_password_hint: 'Enter your E-Mail',
  save: 'Save',
  delete_account: 'Delete account',
  drop_or_select_file_label:'Drop or Browse your file',
  select_file: 'Select file',
  cancel: 'Cancel',
  submit: 'Upload',
  delete: 'Delete',
  ok: 'OK',
  max_file_size: 'Maximum size of uploaded images is 5MB!',
  delete_account_dialog_title: 'Delete Account?',
  delete_account_dialog_message: 'Your account will be deleted and you will lose all your data!',
  email_not_verified: 'E-Mail is not verified!',
  email_verified: 'E-Mail is verified',
  send_verification_email: 'Send verification E-Mail',
  send_verification_email_again: 'Send verification E-Mail again',
  delete_role_dialog_message:'This Role will be deleted',
  delete_role_dialog_title:'Delete this role?',
  users: 'Users',
  edit: 'Edit',
  online: 'Online',
  offline: 'Offline',
  no_connection_warning: 'No connection!',
  undefined_label: ' N/A ',
  undefined_la: ' N/A ',
  no_connection: 'No connection',
  error: 'Error!',
  profiles: 'Profiles',
  gallery_label: 'Escorts Near Me',
  create_profile: 'Create profile',
  edit_profile: 'Edit Profile',
  delete_profile_title: 'Delete this profile?',
  delete_profile_message: 'The escort profile will be deleted!',
  error_required_field: 'Required Field',
  error_geo: 'To continue, please turn on Location Services.',
  about_label: 'About Us',
  error_age_field: 'No kids allowed!',
  smoking_label: 'Smoking',
  occasionally_label: 'Occasionally',
  dress: 'Dress Size',
  age_label: 'Age',
  age_hint: 'Enter your age',
  breast_label: 'Breast Size',
  breast_hint: 'Enter breast size',
  eyes_label: 'Eyes color',
  service_label: 'Select service',
  service: 'Availability',
  available:'Available right now!',
  undefined_inla: ' ',
  undefined_outla: ' ',
  true_inla: 'Incall',
  true_outla: 'Outcall',
  false_outla: ' ',
  false_inla: ' ',
  outcall_label: 'Outcall',
  outcall_la: 'Outcall Service',
  incall_label: 'Incall',
  incall_la: 'Incall Service',
  dress_hint: 'Enter Dress Size',
  height_hint: 'Enter your height',
  height_label: 'Height',
  weight_label: 'Weight',
  weight_hint: 'Enter your weight',
  amber_label: 'Amber',
  green_label: 'Green',
  purple_label: 'Purple',
  blue_label: 'Blue',
  gray_label: 'Gray',
  hazel_label: 'Hazel',
  hair_label: 'Hair Color',
  red_label: 'Red',
  grey: 'Grey',
  blonde_label: 'Blonde',
  brunette_label: 'Brunette',
  black_label: 'Black',
  brown_label: 'Brown',
  other_label: 'Other',
  nationality_label: 'Nationality',
  al_la: 'Albanian',
  ar_la: 'Argentinian',
  dz_la: 'Algerian',
  am_la: 'Armenian',
  au_la: 'Australian',
  at_la: 'Austrian',
  az_la: 'Azerbaijani',
  bs_la: 'Bosnian',
  by_la: 'Belarusian',
  be_la: 'Belgian',
  bo_la: 'Bolivian',
  br_la: 'Brazilian',
  bg_la: 'Bulgarian',
  cm_la: 'Cameroonian',
  cat_la: 'Catalan',
  ca_la: 'Canadian',
  cl_la: 'Chilean',
  cn_la: 'Chinese',
  co_la: 'Colombian',
  cr_la: 'Costa Rican',
  hr_la: 'Croatian',
  cu_la: 'Cuban',
  cz_la: 'Czech',
  dk_la: 'Danish',
  do_la: 'Dominican',
  ec_la: 'Ecuadorian',
  eg_la: 'Egyptian',
  ee_la: 'Estonian',
  fi_la: 'Finnish',
  fr_la: 'French',
  ge_la: 'Georgian',
  de_la: 'German',
  gr_la: 'Greek',
  gb_la: 'British',
  en_la: 'English',
  sct_la: 'Scottish',
  ht_la: 'Haitian',
  he_la: 'Hebrew',
  hi_la: 'Hindi',
  hn_la: 'Honduran',
  hu_la: 'Hungarian',
  in_la: 'Indian',
  is_la: 'Icelandic',
  ga_la: 'Irish',
  id_la: 'Indonesian',
  il_la: 'Israeli',
  it_la: 'Italian',
  jm_la: 'Jamaican',
  jp_la: 'Japanese',
  kr_la: 'Korean',
  kz_la: 'Kazakh',
  lv_la: 'Latvian',
  lt_la: 'Lithuanian',
  my_la: 'Malaysian',
  mk_la: 'Macedonian',
  mx_la: 'Mexican',
  md_la: 'Moldovan',
  ma_la: 'Moroccan',
  nl_la: 'Dutch',
  ni_la: 'Nicaraguan',
  ng_la: 'Nigerian',
  no_la: 'Norwegian',
  pk_la: 'Pakistani',
  py_la: 'Paraguayan',
  fa_la: 'Persian',
  pl_la: 'Polish',
  pt_la: 'Portuguese',
  ro_la: 'Romanian',
  ru_la: 'Russian',
  rs_la: 'Serbian',
  sk_la: 'Slovak',
  si_la: 'Slovenian',
  es_la: 'Spanish',
  se_la: 'Swedish',
  ch_la: 'Swiss',
  th_la: 'Thai',
  tn_la: 'Tunisian',
  tr_la: 'Turkish',
  ua_la: 'Ukrainian',
  us_la: 'American',
  uz_la: 'Uzbek',
  cy_la: 'Welsh',
  ve_la: 'Venezuelan',
  vn_la: 'Vietnamese',
  orientation_label: 'Orientation',
  straight_label: 'Straight',
  bicurious_label: 'Bi-curious',
  bisexual_label: 'Bisexual',
  description_label: 'Description',
  description_hint: 'Enter short description',
  name_label: 'Name',
  name_hint: 'Enter name',
  details_label: 'Details',
  details_la: 'Details',
  details:'Update Location',
  contact_label:'Contact Details',
  info_label: 'Info',
  rates_label: 'Rates',
  rates: 'Rates from',
  distance: 'Distance(miles)  ',
  miles_away: ' miles away',
  onehour_label:'One Hour',
  onein_label: 'Prices',
  price_hint: 'Enter price (i.e. £500)',
  addhour_label:'Extra Hour',
  overnight_label: 'Overnight',
  twentyfourhour_label: '24 Hrs',
  threedays_label: '3 Days',
  week_label: '1 Week',
  phone_label: 'Phone number',
  website_hint: 'i.e. www.mywebsite.com',
  website_label: 'Personal website',
  value_label: 'Value',
  public_chats: 'Public chat',
  delete_message_title: 'Delete message?',
  delete_message_message: 'Message will be deleted!',
  users_count_title: '{number} Users',
  user_registrationg_graph_label: 'User registrations',
  required: 'Required',
  empty_favorites: 'Empty List',
  twitter: 'Twitter',
  phone: 'Phone: ',
  google: 'Google',
  favourites: 'Favourites',
  favorites_description: 'You have no Favourites!',
  twitter_color: '#36A2EB',
  phone_color: '#90A4AE',
  google_color: '#EA4335',
  password_color: '#4CAF50',
  chats: 'Chats',
  write_message_hint: 'Write message...',
  load_more_label: 'More...',
  my_location: 'My Location',
  location_label: 'Update Location',
  select_user: 'Select user',
  operator_like_label: 'like',
  operator_notlike_label: 'not like',
  operator_equal_label: 'equal',
  operator_notequal_label: 'not equal',
  operator_novalue_label: 'no value',
  administration: 'Admin',
  roles: 'Roles',
  grants: 'Grants',
  private: 'Private',
  public: 'Public',
  time_label:'Time',
  grant_read_profiles: 'Read profiles',
  grant_create_profile: 'Create escort',
  grant_edit_profile: 'Edit escort',
  grant_delete_profile: 'Delete escort',
  is_admin_label: 'Administrator',
  select_field: 'Select field',
  sorting: 'Sorting',
  filters: 'Filters',
  filter: 'Filter',
  add_filter: 'Add filter',
  delete_filter: 'Delete filter',
  change_sort_orientation: 'Change orientation',
  enable_case_sensitivity: 'Case sensitive',
  disable_case_sensitivity: 'Case insensitive',
  hint_autocomplete: 'Select',
  enter_query_text: 'Enter text',
  email_label: 'Email',
  close_filter: 'Close filter',
  close: 'Close',
  open_filter: 'Open filter',
  select_operator: 'Select operator',
  operator_contains_label: 'Operator',
  not_match_found: 'No match found',
  edit_user: 'Edit user',
  edit_role: 'Edit Role',
  firestore: 'Firestore',
  ads:'Free Classified Ads',
  ads_description:'Attract more clients! Earn more money!',
  no_thanks: 'No Thanks',
  post:'Post Your Ad',
  user_label_search: 'Search',
  ios_prompt_title: 'Add to Home Screen',
  ios_prompt_close: 'Close',
  ios_prompt_body:
    'Escorts⁺ has app functionality. Add it to your home screen to run like any other installed app. Escorts⁺ do not need to be downloaded.',
  ios_prompt_share_button: "1) Press the 'Share' button",
  ios_prompt_add_to_home_button: "2) Press 'Add to Home Screen'",
});

export default messages;
