import { defineMessages } from 'react-intl'

const messages = defineMessages({
  app_name: 'Escorts⁺',
  dashboard: 'Дашборд',
  distance: 'Расстояние(км)',
  miles_away: ' км от вас',
  about_label: 'О нас',
  home_label: 'Главная',
  '404': '404',
  warning_404:'Поиск эскорт девушек в вашем регионе',
  warning_404_title:'Эскорт объявления',
  warning_404_message: 'Ошибка 404!',
  warning_404_description: 'Страница не найдена',
  settings: 'Настроики',
  search_settings: 'Настройка поиска',
  language: 'Язык',
  languages_label: 'Знание языков',
  height_hint: 'Введите свой рост',
  height_label: 'Рост',
  weight_label: 'Вес',
  weight_hint: 'Введите свой вес',
  theme: 'Темы',
  email_notifications: 'Email-уведомления',
  responsive: 'Адаптивный',
  blueGrey: 'Серая',
  profiles: 'Анкеты',
  undefined_label: 'Н/Д',
  undefined_la: 'Н/Д',
  available: 'Доступнa прямо сейчас!',
  details_label: 'Обо мне',
  details_la: 'Данные',
  details: 'Обновление местоположения',
  rates_label: 'тарифы',
  onein_label: 'Цена за час',
  rates:'Расценки от',
  en: 'English',
  de: 'Deutsch',
  ru: 'Русский',
  es: 'Español',
  it: 'Italiano',
  ro: 'Română',
  al_la: 'Албанка',
  ar_la: 'Аргентинка',
  dz_la: 'Алжирка',
  am_la: 'Армянка',
  au_la: 'Австралийка',
  at_la: 'Австриячка',
  az_la: 'Азербайджанка',
  bs_la: 'Боснийка',
  by_la: 'Белоруска',
  be_la: 'Бельгийка',
  bo_la: 'Боливийка',
  do_la: 'Доминиканка',
  br_la: 'Бразильянка',
  bg_la: 'Болгарка',
  cm_la: 'Камерунка',
  cz_la: 'Чешка',
  ca_la: 'Канадка',
  cl_la: 'Чилийка',
  cn_la: 'Китаянка',
  co_la: 'Колумбийка',
  cr_la: 'Костарикaнка',
  hr_la: 'Хорватка',
  cu_la: 'Кубинка',
  dk_la: 'Датчанка',
  ec_la: 'Эквадорка',
  eg_la: 'Египтянка',
  ee_la: 'Эстонка',
  fi_la: 'Финляндка',
  fr_la: 'Француженкa',
  ge_la: 'Грузинка',
  de_la: 'Немка',
  gr_la: 'Гречанка',
  gb_la: 'Британка',
  en_la: 'Англичанка',
  sct_la: 'Шотландка',
  cy_la: 'Валлийка',
  ht_la: 'Гаитянка',
  in_la: 'Индуска',
  hn_la: 'Гондураска',
  hu_la: 'Венгерка',
  is_la: 'Исландка',
  ga_la: 'Ирландка',
  id_la: 'Индонезийка',
  il_la: 'Израильтянка',
  it_la: 'Итальянка',
  jm_la: 'Ямайка',
  jp_la: 'Японка',
  kr_la: 'Кореянка',
  kz_la: 'Казашка',
  lv_la: 'Латвийка',
  lt_la: 'Литовка',
  my_la: 'Малайзийка',
  mk_la: 'Македонка',
  mx_la: 'Мексиканка',
  md_la: 'Молдаванка',
  ma_la: 'Марокканкa',
  nl_la: 'Нидерландка',
  ni_la: 'Никарагуанка',
  ng_la: 'Нигерийка',
  no_la: 'Norwegian',
  pk_la: 'Пакистанка',
  py_la: 'Парагвайка',
  fa_la: 'Иранка',
  pl_la: 'Полячка',
  pt_la: 'Португалка',
  ro_la: 'Румынка',
  ru_la: 'Русская',
  rs_la: 'Сербка',
  sk_la: 'Словачка',
  si_la: 'Словенка',
  es_la: 'Испанка',
  se_la: 'Шведка',
  ch_la: 'Швейцарка',
  th_la: 'Тайка',
  tn_la: 'Туниска',
  tr_la: 'Турчанка',
  ua_la: 'Украинка',
  us_la: 'Американка',
  uz_la: 'Узбечка',
  ve_la: 'Венесуэлка',
  vn_la: 'Вьетнамка',
  dark: 'Темная',
  light: 'Светла',
  ics: 'ICS',
  sign_out: 'Выход',
  sign_in: 'Вход',
  sign_up: 'Зарегистрироваться',
  found: 'Найдено',
  girls_la: 'девушек в вашем районе',
  girl_la: 'девушку в этом районе',
  join_us: 'Добавить анкету',
  sign_in_with_google: 'Войти через Гугл',
  sign_in_with_twitter: 'Войти через Твиттер',
  'link_with_google.com': 'Привязанные к аккаунту Гугл',
  'link_with_twitter.com': 'Привязанные к аккаунту Твиттер',
  my_account: 'Мой аккаунт',
  name: 'Имя',
  email: 'eмейл',
  password: 'Пароль',
  new_password: 'Новый пароль',
  confirm_password: 'Повторите пароль',
  forgort_password: 'Забыли пароль?',
  reset_password: 'Сброс пароля',
  change_password: 'Изменить пароль',
  change_photo: 'Сменить фото',
  change_email: 'Сменить емейл',
  reset_password_hint: 'Введите ваш емейл',
  save: 'Сохранить',
  favourites: 'Избранное',
  delete_account: 'Удалить аккаунт',
  drop_or_select_file_label:'Выберите или перетащите файл сюда',
  operator_contains_label: 'оператор содержит',
  select_file: 'Выберите файл',
  cancel: 'Аннулировать',
  submit: 'Загрузить',
  delete: 'Удалить',
  ok: 'OK',
  max_file_size: 'Максимальный размер фото - 5Мб!',
  delete_account_dialog_title: 'Удалить Ваш аккаунт?',
  delete_account_dialog_message: 'Ваш аккаунт будет удален и вы потеряете все ваши данные!',
  email_not_verified: 'E-mail не подтвержден!',
  email_verified: 'E-mail подтвержден',
  send_verification_email: 'Отправить письмо с подтверждением',
  send_verification_email_again: 'Выслать письмо для активации повторно',
  policy_label: 'Конфиденциальность',
  terms_label: 'Правила и Условия',
  legal: 'Правовые акты',
  legal_la: 'Правила и Условия использования',
  legal_label: 'Продолжая, вы подтверждаете, что вам больше 18 лет, и вы прочитали и согласны соблюдать',
  wname: 'Рабочее имя',
  users: 'Пользователи',
  edit: 'Редактировать',
  online: 'Онлайн',
  offline: 'Офлайн',
  no_connection_warning: 'Нет соединения!',
  no_connection: 'Нет соединения',
  error_required_field: 'Отсутствует обязательное поле',
  error_geo: 'Чтобы продолжить, пожалуйста, включите определение местоположения.',
  error: 'Ошибка!',
  error_age_field: 'Дети не допускаются!',
  create_profile: 'Создать анкету',
  edit_profile: 'Редактировать анкету',
  delete_profile_title: 'Удалить анкету?',
  delete_profile_message: 'Профиль будет удален!',
  gallery_label: 'Девушки для досуга',
  breast_label:'Размер груди',
  breast_hint: 'Введите размер груди',
  profile: 'Профиль',
  height: 'Рост',
  dress: 'Размер платья',
  smoking_label: 'Курящая',
  yes_label:'Да',
  no_label:'Нет',
  piercings_label: 'Пирсинг',
  occasionally_label:'Иногда',
  hair_label: 'Цвет волос',
  red_label: 'Рыжая',
  blonde_label: 'Блондинка',
  brunette_label: 'Брюнетка',
  black_label: 'Черные',
  brown_label: 'Шатенка',
  other_label: 'Другой',
  eyes_label: 'Цвет глаз',
  amber_label: 'Карие',
  green_label: 'Зеленые',
  purple_label:'Пу́рпур',
  blue_label: 'Голубые',
  gray_label: 'Серая',
  hazel_label: 'Коричневые',
  age_label: 'Возраст',
  age_hint: 'Введите свой возраст',
  phone_label: 'Номер телефона',
  phone_hint: 'Контактный Телефон',
  show_tel: 'Показать номер',
  service_label: 'Услуги',
  service: 'Встреча',
  undefined_inla: ' ',
  undefined_outla: ' ',
  true_inla: 'Квартира',
  true_outla: 'Выезд',
  false_outla: ' ',
  false_inla: ' ',
  outcall_label: 'Выезд',
  outcall_la: 'Только выезд',
  incall_label: 'Квартира',
  incall_la: 'Апартаменты',
  nationality_label: 'Национальность',
  orientation_label: 'Ориентация',
  description_label: 'Описание',
  description_hint: 'Введите описание',
  name_label: 'Имя',
  name_hint: 'Введите Имя',
  straight_label: 'Гетеро',
  bicurious_label: 'Би-любопытная',
  bisexual_label: 'Бисексуальная',
  public_chats: 'Публичный чат',
  delete_message_title: 'Удалить сообщение?',
  delete_message_message: 'Сообщение будет удалено!',
  users_count_title: '{количество} Пользователей',
  user_registrationg_graph_label: 'Регистрация пользователей',
  required: 'Обязательно',
  twitter: 'Твиттер',
  phone: 'Телефон: ' ,
  google: 'Гугл',
  twitter_color: '#36A2EB',
  phone_color: '#90A4AE',
  google_color: '#EA4335',
  password_color: '#4CAF50',
  chats: 'Чат',
  write_message_hint: 'Написать сообщение...',
  load_more_label: 'Больше...',
  my_location: 'Моё местоположение',
  location_label: 'Обновление местоположения',
  select_user: 'Выберите пользователя',
  operator_like_label: 'нравиться',
  operator_notlike_label: 'не нравишься',
  operator_equal_label: 'равный',
  operator_notequal_label: 'не равный',
  operator_novalue_label: 'нет значения',
  administration: 'Админ',
  roles: 'Роли',
  grants: 'Гранты',
  private: 'Приватный',
  public: 'Публичный',
  grant_read_profiles: 'Посмотреть Профили',
  grant_create_profile: 'Создать компанию',
  grant_edit_profile: 'Редактировать компанию',
  grant_delete_profile: 'Удалить компанию',
  is_admin_label: 'Администратор',
  select_field: 'Выберите опцию',
  sorting: 'Сортировать',
  filters: 'Фильтры',
  filter: 'Фильтр',
  add_filter: 'Добавить фильтр',
  delete_filter: 'Удалить фильтр',
  change_sort_orientation: 'Изменение ориентации',
  enable_case_sensitivity: 'Чувствительность к регистру',
  hint_autocomplete: 'Выберите',
  enter_query_text: 'Введите текст',
  email_label: 'Эмейл',
  close_filter: 'Закрыть фильтр',
  open_filter: 'Открыть фильтр',
  select_operator: 'Выбoр оператора',
  not_match_found: 'Совпадений не найдено',
  edit_user: 'Редактирование пользователя',
  firestore: ' Фиресторе',
  user_label_search: 'Поиск',
  mark_chat_as_unread: 'Пометить как непрочитанное',
  delete_chat: 'Удалить чат',
  search: 'Поиск',
  update_available: 'Доступна новая версия этого приложения.',
  load_update: 'Обновить',
  enable_notifications_message: 'Включить уведомления?',
  enable: 'Включить',
  ads:'Бесплатные Объявления',
  ads_description:'Привлекайте больше клиентов! Заработайте больше денег!',
  post:'Подать объявление',
  no_thanks: 'Нет, Спасибо',
  creation_time: 'Время создания',
  night_mode: 'Тёмная тема',
  day_mode: 'Светлая тема',
  default: 'Стандарт',
  red: 'Красная',
  grey: 'Сивая',
  green: 'Зеленая',
  contact_label: 'Контакт',
  info_label: 'Инфо',
  website_hint: 'т.е. www.сайт.ru',
  website_label: 'Сайт:',
  time_label: 'Время',
  onehour_label:'За час',
  price_hint: 'Введите цену (т.е. 5000₽)',
  addhour_label:'Доп. час',
  overnight_label: 'Ночь',
  twentyfourhour_label: 'Сутки',
  threedays_label: '3 дня',
  week_label: 'Неделя',
  sugar_label: 'Ищу спонсора',
  sugar_hint: 'Вознаграждение (т.е. 95000₽/в месяц)',
  daddy_label: 'Желает найти спонсора',
  notifications: 'Уведомления',
  disable_notifications_dialog_title: 'Отключить уведомления',
  disable_notifications_dialog_message: 'Все уведомления на всех ваших устройствах будут отключены!',
  update_title: 'Доступно обновление!',
  update_message: 'Нажмите здесь, чтобы получить новую версию.',
  install: 'Установить',
  ios_prompt_title: 'Добавить на экран «Домой»',
  ios_prompt_close: 'Закрыть',
  ios_prompt_body:
    'Escorts⁺ имеет функциональность приложения. Добавьте его на экран «Домой», чтобы использовать как любое другое установленное приложение. Escorts⁺ не нужно скачивать.',
  ios_prompt_share_button: "1) Нажмите кнопку «Поделиться»",
  ios_prompt_add_to_home_button: "2) Нажмите На экран «Домой»",
})

export default messages
