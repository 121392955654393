import { defineMessages } from 'react-intl';

const messages = defineMessages({
  app_name: 'Escorts⁺',
  dashboard: 'Panel',
  about_label: 'Acerca',
  home_label: 'Inicio',
  _label: 'N/D',
  '404': '404',
  warning_404:'Encuentra las mejores escorts en tu área local',
  warning_404_title:'Anuncios Clasificados De Escorts',
  warning_404_message: 'No Se Ha Encontrado Nada!',
  warning_404_description: 'Desafortunadamente, no se encontró nada en esta ubicación.',
  settings: 'Ajustes',
  search_settings: 'Opciones de búsqueda',
  language: 'Idioma',
  languages_label:'Idiomas',
  theme: 'Tema',
  email_notifications: 'Notificaciones por correo electrónico',
  yes_label: 'Sí',
  no_label: 'No',
  policy_label: 'Política de Privacidad',
  terms_label: 'Términos y condiciones',
  legal:'Dato Jurídico',
  legal_la:  ' Privacidad y Condiciones',
  legal_label: 'Al proceder, está confirmando que tiene más de 18 años y que ha leído, comprendido y aceptado ',
  piercings_label: 'Piercings',
  sugar_label: 'Busco un papi de azúcar',
  sugar_hint: 'Expectativa (i.e. 9000€/mensual)',
  daddy_label: 'Una expectativa de bebé de azúcar',
  responsive: 'Responsivo',
  phone_hint: 'Teléfono De Contacto',
  show_tel: 'Mostrar número',
  en: 'English',
  de: 'Deutsch',
  ru: 'Русский',
  es: 'Español',
  it: 'Italiano',
  ro: 'Română',
  blueGrey: 'Gris',
  dark: 'Oscuro',
  light: 'Claro',
  ics: 'ICS',
  sign_out: 'Salir',
  sign_in: 'Ingresar',
  sign_up: 'Registrarse',
  found: 'Se encontraron',
  girls_la: 'chicas de tu zona',
  girl_la: 'chica en tu área',
  join_us: 'Anunciar aquí',
  sign_in_with_google: 'Ingresar con Google',
  sign_in_with_twitter: 'Ingresar con Twitter',
  'link_with_google.com': 'Enlazar con Google',
  'link_with_twitter.com': 'Enlazar con Twitter',
  my_account: 'Mi cuenta',
  wname: 'Escort Nombre',
  email: 'E-Mail',
  password: 'Contraseña',
  new_password: 'Nueva Contraseña',
  confirm_password: 'Confirmar Contraseña',
  forgort_password: '¿Olvidó la Contraseña?',
  reset_password: 'Restablecer Contraseña',
  change_password: 'Cambiar Contraseña',
  change_photo: 'Cambiar Foto',
  change_email: 'Cambiar E-Mail',
  reset_password_hint: 'Ingrese su E-Mail',
  save: 'Guardar',
  delete_account: 'Eliminar cuenta',
  drop_or_select_file_label:'Subir una imagen',
  select_file: 'Seleccionar Archivo',
  cancel: 'Cancelar',
  submit: 'Subir',
  delete: 'Borrar',
  ok: 'OK',
  max_file_size: 'El tamaño máximo de las imágenes subidas es 5MB!',
  delete_account_dialog_title: '¿Eliminar cuenta?',
  delete_account_dialog_message: '¡Su cuenta será eliminada y perderá toda su información!',
  email_not_verified: '¡Correo electrónico sin verificar!',
  email_verified: 'Correo electrónico verificado',
  send_verification_email: 'Enviar correo de verificación',
  send_verification_email_again: 'Reenviar correo de verificación',
  delete_role_dialog_message:'This Role will be deleted',
  delete_role_dialog_title:'Delete this role?',
  users: 'Usuario',
  edit: 'Editar',
  online: 'Conectado',
  offline: 'Desconectado',
  no_connection_warning: '¡Sin conexión!',
  undefined_label: ' N/D ',
  undefined_la: ' N/D ',
  no_connection: 'No hay conexión',
  error: '¡Error!',
  profiles: 'Perfiles',
  gallery_label: 'De chicas cerca de mi',
  create_profile: 'Crear un perfil',
  edit_profile: 'Editar perfil',
  delete_profile_title: 'Eliminar este perfil?',
  delete_profile_message: 'Perfil será eliminada!',
  error_required_field: 'Campo requerido',
  error_geo: 'Para continuar, activa los servicios de localización.',
  error_age_field: 'No se permiten niños!',
  smoking_label: 'Fumador',
  occasionally_label: 'Ocasionalmente',
  dress: 'Tamaño del vestido',
  age_label: 'Edad',
  age_hint: 'Ingrese su edad',
  breast_label: 'Talla de sostén',
  breast_hint: 'Ingresar talla de sostén',
  eyes_label: 'Color ojos',
  service_label: 'Seleccionar servicio',
  service: 'Disponibilidad',
  available:'¡Disponible ahora!',
  undefined_inla: ' ',
  undefined_outla: ' ',
  true_inla: 'Domicilio',
  true_outla: 'Salidas',
  false_outla: ' ',
  false_inla: ' ',
  outcall_label: 'Salidas',
  outcall_la: 'Salidas',
  incall_label: 'Domicilio',
  incall_la: 'Domicilio',
  dress_hint: 'Ingresar tamaño del vestido',
  height_hint: 'Ingresar su Estatura',
  height_label: 'Estatura',
  weight_label: 'Peso',
  weight_hint: 'Ingresar su peso',
  amber_label: 'Ambar',
  green_label: 'Verde',
  purple_label: 'Morado',
  blue_label: 'Azul',
  gray_label: 'Gris',
  hazel_label: 'Avellana',
  hair_label: 'Color de pelo',
  red_label: 'Rojo',
  grey: 'Gris',
  blonde_label: 'Rubia',
  brunette_label: 'Morena',
  black_label: 'Negro',
  brown_label: 'Marrón',
  other_label: 'Otro',
  nationality_label: 'Nacionalidad',
  al_la: 'Albanés',
  ar_la: 'Argentina',
  dz_la: 'Argelino',
  am_la: 'Armenio',
  au_la: 'Australiano',
  at_la: 'Austriaco',
  az_la: 'Azerbayán',
  bs_la: 'Bosnio',
  by_la: 'Belarús',
  be_la: 'Belga',
  bo_la: 'Boliviano',
  br_la: 'Brasileño',
  bg_la: 'Búlgaras',
  cm_la: 'Camerún',
  cat_la: 'Catalana',
  ca_la: 'Canadiense',
  cl_la: 'Chileno',
  cn_la: 'Chino',
  co_la: 'Colombiano',
  cr_la: 'Costa Rica',
  hr_la: 'Croata',
  cu_la: 'Cubano',
  cz_la: 'Checo',
  dk_la: 'Danés',
  do_la: 'Dominicano',
  ec_la: 'Ecuatoriana',
  eg_la: 'Egipcio',
  ee_la: 'Estonia',
  fi_la: 'Finlandés',
  fr_la: 'Francés',
  ge_la: 'Georgia',
  de_la: 'Alemán',
  gr_la: 'Griego',
  gb_la: 'Británica',
  en_la: 'Inglesa',
  sct_la: 'Escocesa',
  ht_la: 'Haitiana',
  he_la: 'Hebrea',
  hi_la: 'Hindi',
  hn_la: 'Hondureña',
  hu_la: 'Húngara',
  in_la: 'Indio',
  is_la: 'Islandesa',
  ga_la: 'Irlandés',
  id_la: 'Indonesio',
  il_la: 'Israelí',
  it_la: 'Italiano',
  jm_la: 'Jamaicana',
  jp_la: 'Japonés',
  kr_la: 'Koreano',
  kz_la: 'Kazajo',
  lv_la: 'Letona',
  lt_la: 'Lituana',
  my_la: 'Malasio',
  mk_la: 'Macedonia',
  mx_la: 'Mexicana',
  md_la: 'Moldavo',
  ma_la: 'Marroquí',
  nl_la: 'Neerlandés',
  ni_la: 'Nicaragüense',
  ng_la: 'Nigeriano',
  no_la: 'Noruego',
  pk_la: 'Pakistaní',
  py_la: 'Paraguayo',
  fa_la: 'Persa',
  pl_la: 'Polaco',
  pt_la: 'Portuguesa',
  ro_la: 'Rumano',
  ru_la: 'Russo',
  rs_la: 'Serbia',
  sk_la: 'Eslovaca',
  si_la: 'Eslovena',
  es_la: 'Española',
  se_la: 'Sueca',
  ch_la: 'Suiza',
  th_la: 'Tailandesa',
  tn_la: 'Tunecina',
  tr_la: 'Turca',
  ua_la: 'Ucraniana',
  us_la: 'Americana',
  uz_la: 'Uzbeka',
  cy_la: 'Galés',
  ve_la: 'Venezolana',
  vn_la: 'Vietnamita',
  orientation_label: 'Orientación',
  straight_label: 'Hetero',
  bicurious_label: 'Bi-curioso',
  bisexual_label: 'Bisexual',
  description_label: 'Descripci',
  description_hint: 'Introduzca una breve descripción',
  name_label: 'Nombre',
  name_hint: 'Ingresar Nombre',
  details_label: 'Detalles',
  details_la: 'Detalles',
  contact_label:'Detalles de contacto',
  info_label: 'Info',
  rates_label: 'Tarifas',
  rates: 'Tarifas desde',
  distance: 'Distancia(millas)  ',
  miles_away: ' millas lejos',
  onehour_label:'Una hora',
  onein_label: 'Precios',
  price_hint: 'Ingrese precio (es decir 250€)',
  addhour_label:'Hora Extra',
  overnight_label: 'Noche',
  twentyfourhour_label: '24 Horas',
  threedays_label: '3 Días',
  week_label: '1 Semana',
  phone_label: 'Número de teléfono',
  website_hint: 'es decir www.sitio-web.es',
  website_label: 'Sitio web',
  value_label: 'Valor',
  public_chats: 'Chat público',
  delete_message_title: '¿Borrar mensaje?',
  delete_message_message: '¡El mensaje será borrado!',
  users_count_title: '{number} Usuarios',
  user_registrationg_graph_label: 'Registros de usuarios',
  required: 'Requerido',
  empty_favorites: 'Lista vacía',
  twitter: 'Twitter',
  phone: 'Teléfono: ',
  google: 'Google',
  favourites: 'Favoritos',
  favorites_description: 'Tu no tienes favoritos!',
  twitter_color: '#36A2EB',
  phone_color: '#90A4AE',
  google_color: '#EA4335',
  password_color: '#4CAF50',
  chats: 'Chats',
  write_message_hint: 'Escribir mensaje...',
  load_more_label: 'Más...',
  my_location: 'Mi ubicación',
  location_label: 'Actualizar ubicación',
  select_user: 'Seleccionar usuario',
  operator_like_label: 'like',
  operator_notlike_label: 'not like',
  operator_equal_label: 'equal',
  operator_notequal_label: 'not equal',
  operator_novalue_label: 'no value',
  administration: 'Administración',
  roles: 'Roles',
  grants: 'Grants',
  private: 'Privado',
  public: 'Público',
  time_label:'Tiempos',
  grant_read_profiles: 'Leer perfiles',
  grant_create_profile: 'Create escort',
  grant_edit_profile: 'Edit escort',
  grant_delete_profile: 'Delete escort',
  is_admin_label: 'Administrador',
  select_field: 'Seleccionar campo',
  sorting: 'Orden',
  filters: 'Filtros',
  filter: 'Filtro',
  add_filter: 'Agregar filtro',
  delete_filter: 'Borrar filtro',
  change_sort_orientation: 'Cambiar orientación',
  enable_case_sensitivity: 'Sensibilidad de mayúsculas',
  disable_case_sensitivity: 'Case insensitive',
  hint_autocomplete: 'Seleccionar',
  enter_query_text: 'Ingresar texto',
  email_label: 'Email',
  close_filter: 'Cerrar filtro',
  close: 'Cerrar',
  open_filter: 'Abrir filtro',
  select_operator: 'Seleccione operador',
  operator_contains_label: 'Operator',
  not_match_found: 'No se encontraron coincidencias',
  edit_user: 'Editar usuario',
  edit_role: 'Edit Role',
  firestore: 'Firestore',
  night_mode: 'Tema Oscuro',
  day_mode: 'Tema claro',
  default: 'Básica',
  ads:'Anuncios Clasificados Gratis',
  ads_description:'Atraer a más clientes! Ganar más dinero!',
  no_thanks: 'No, gracias',
  post:'Pon tu anuncio',
  user_label_search: 'Buscar',
  install: 'Instalar',
  ios_prompt_title: 'Añadir a la pantalla de inicio',
  ios_prompt_close: 'Cerrar',
  ios_prompt_body:
    'Escorts⁺ tiene funcionalidad de aplicación. Agréguelo a su pantalla de inicio para ejecutar como cualquier otra aplicación instalada. Escorts⁺ no necesitan ser descargados.',
  ios_prompt_share_button: "1) Toque el botón Compartir",
  ios_prompt_add_to_home_button: "2) Toque 'Añadir a la pantalla de inicio'",
});

export default messages;
