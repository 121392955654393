import React from 'react'
import { Route } from 'react-router-dom'
import RestrictedRoute from 'rmw-shell/lib/containers/RestrictedRoute'
import makeLoadable from 'rmw-shell/lib/containers/MyLoadable'

const MyLoadable = (opts, preloadComponents) =>
  makeLoadable({ ...opts, firebase: () => import('./firebase') }, preloadComponents)
const AsyncMyAccount = MyLoadable({ loader: () => import('../pages/MyAccount') })
const AsyncDashboard = MyLoadable({ loader: () => import('../pages/Dashboard') })
const AsyncGallery = MyLoadable({ loader: () => import('../pages/Gallery') })
const AsyncAbout = MyLoadable({ loader: () => import('../pages/About') })
const AsyncPrivacy = MyLoadable({ loader: () => import('../pages/Privacy') })
const AsyncTerms = MyLoadable({ loader: () => import('../pages/Terms') })
const AsyncProfile = MyLoadable({ loader: () => import('../pages/Profiles/Profile') })
const AsyncNewProfile = MyLoadable({ loader: () => import('../pages/Profiles/NewProfile') })
const AsyncProfiles = MyLoadable({ loader: () => import('../pages/Profiles/Profiles') }, [AsyncProfile])
const AsyncFavourites = MyLoadable({ loader: () => import('../pages/Favourites') })


const routes = [
  <Route type="public" path="/" exact component={AsyncGallery} />,
  <Route type="public" path="/gallery" exact component={AsyncGallery} />,
  <Route type="public" path="/about" exact component={AsyncAbout} />,
  <Route type="public" path="/favourites" exact component={AsyncFavourites} />,
  <Route type="public" path="/privacy" exact component={AsyncPrivacy} />,
  <Route type="public" path="/terms" exact component={AsyncTerms} />,
  <RestrictedRoute type="private" path="/my_account" exact component={AsyncMyAccount} />,
  <RestrictedRoute type="private" path="/dashboard" exact component={AsyncDashboard} />,
  <RestrictedRoute type="private" path="/profiles" exact component={AsyncProfiles} />,
  <RestrictedRoute type="private" path="/profiles/edit/:uid" exact component={AsyncProfile} />,
  <RestrictedRoute type="private" path="/profiles/create" exact component={AsyncNewProfile} />
]

export default routes
