import lightGreen from '@material-ui/core/colors/lightGreen'
import red from '@material-ui/core/colors/red'
import blueGrey from '@material-ui/core/colors/blueGrey'
import pink from '@material-ui/core/colors/pink'
import teal from '@material-ui/core/colors/teal'
import blue from '@material-ui/core/colors/blue'
import deepPurple from '@material-ui/core/colors/deepPurple'

const themes = [
  {
    id: 'default',
    color: blue[500],
  },
  {
    id: 'gray_label',
    color: blueGrey[500],
    source: {
      palette: {
        primary: blueGrey,
        secondary: pink,
        error: red
      }
    }
  },
  {
    id: 'purple_label',
    color: deepPurple[500],
    source: {
      palette: {
        primary: deepPurple,
        secondary: pink,
        error: lightGreen
      }
    }
  },
  {
    id: 'green',
    color: teal[500],
    source: {
      palette: {
        primary: teal,
        secondary: red,
        error: lightGreen
      }
    }
  }
]

export default themes
