import { defineMessages } from 'react-intl';

const messages = defineMessages({
  app_name: 'Escort⁺',
  dashboard: 'Übersicht',
  about_label: 'Über Uns',
  '404': '404',
  warning_404:'Sie finden hier die besten Escorts',
  warning_404_title:'Escort Kleinanzeigen',
  warning_404_message: 'Fehler 404!',
  warning_404_description: 'Seite nicht gefunden',
  settings: 'Einstellungen',
  language: 'Sprache',
  theme: 'Thema',
  responsive: 'Responsive',
  en: 'English',
  de: 'Deutsch',
  ru: 'Русский',
  es: 'Español',
  it: 'Italiano',
  ro: 'Română',
  dark: 'Dark',
  light: 'Light',
  ics: 'ICS',
  email_notifications: 'E-Mail-Benachrichtigungen',
  sign_out: 'Abmelden',
  sign_in: 'Anmelden',
  sign_up: 'Registrieren',
  sign_in_with_google: 'Mit Google anmelden',
  sign_in_with_twitter: 'Mit Twitter anmelden',
  'link_with_google.com': 'Mit Google verbinden',
  'link_with_twitter.com': 'Mit Twitter verbinden',
  my_account: 'Mein Konto',
  name: 'Name',
  email: 'E-Mail',
  password: 'Passwort',
  new_password: 'Neues Passwort',
  confirm_password: 'Passwort bestätigen',
  forgort_password: 'Passwort vergessen?',
  reset_password: 'Passwort zurücksetzten',
  change_password: 'Passwort ändern',
  change_email: 'E-Mail ändern',
  change_photo: 'Foto ändern',
  reset_password_hint: 'Ihre E-Mail eingeben',
  save: 'Speichern',
  delete_account: 'Konto löschen',
  select_file: 'Datei auswählen',
  cancel: 'Abbrechen',
  submit: 'Hochladen',
  delete: 'Löschen',
  ok: 'OK',
  max_file_size: 'Die maximale Fotogröße beträgt 5MB!',
  delete_account_dialog_title: 'Konto löschen?',
  delete_account_dialog_message: 'Dein Konto wird gelöscht und mit ihm alle Daten!',
  email_not_verified: 'E-Mail ist nicht verifiziert!',
  email_verified: 'E-Mail ist verifiziert',
  send_verification_email: 'Verifizierungs E-Mail senden',
  send_verification_email_again: 'Verifizierungs E-Mail wieder senden',
  policy_label: 'Datenschutz',
  terms_label: 'AGB',
  legal:'Rechtsabteilung',
  legal_la: 'Datenschutz & Nutzungsbedingungen',
  legal_label: 'Indem Sie fortfahren, bestätigen Sie, dass Sie über 18 Jahre alt sind und gelesen und verstanden haben ',
  users: 'Benutzer',
  edit: 'Bearbeiten',
  online: 'Online',
  offline: 'Offline',
  no_connection_warning: 'Keine Verbindung!',
  no_connection: 'Keine Verbindung',
  error: 'Fehler!',
  profiles: 'Profile',
  error_geo: 'Bitte aktivieren Sie die Ortungsdienste',
  error_age_field: 'Keine Kinder erlaubt!',
  error_required_field: 'Pflichtfeld',
  create_profile: 'Profile erstellen',
  delete_profile_title: 'Profil löschen?',
  delete_profile_message: 'Profil wird gelöscht!',
  drop_or_select_file_label:'Ziehen Sie Dateien und legen Sie sie im Fenster Dateien übertragen ab, oder klicken Sie auf Dateien auswählen',
  undefined_label: ' k.A. ',
  undefined_la: ' k.A. ',
  full_name_label: 'Voller Name',
  full_name_hint: 'Vollen Namen eingeben',
  no_label: 'Nein',
  yes_label: 'Ja',
  occasionally_label: 'Manchmal',
  description_label: 'Beschreibung',
  description_hint: 'Beschreibung eingeben',
  name_label: 'Name',
  wname: 'Arbeitsname',
  name_hint: 'Name eingeben',
  public_chats: 'Öffentlicher Chat',
  delete_message_title: 'Nachricht löschen?',
  delete_message_message: 'Die Nachrricht wird gelöscht!',
  users_count_title: '{number} Benutzer',
  user_registrationg_graph_label: 'Benutzer registrierungen',
  required: 'Erforderlich',
  twitter: 'Twitter',
  phone: 'Phone: ',
  google: 'Google',
  twitter_color: '#36A2EB',
  phone_color: '#90A4AE',
  google_color: '#EA4335',
  password_color: '#4CAF50',
  chats: 'Chats',
  write_message_hint: 'Eine Nachricht schreiben...',
  load_more_label: 'Mehr...',
  my_location: 'Mein Standort',
  location_label: 'Standort aktualisieren',
  select_user: 'Benutzer auswählen',
  operator_like_label: 'wie',
  operator_notlike_label: 'nicht wie',
  operator_equal_label: 'gleich',
  operator_contains_label: 'enthalten',
  operator_notequal_label: 'nicht equal',
  operator_novalue_label: 'kein Wert',
  administration: 'Administration',
  roles: 'Rollen',
  grants: 'Berechtigungen',
  private: 'Privatgelände',
  public: 'Öffentlich',
  is_admin_label: 'Administrator',
  select_field: 'Feld auswählen',
  sorting: 'Sortierung',
  filters: 'Filter',
  filter: 'Filter',
  add_filter: 'Filter hinzufügen',
  delete_filter: 'Filter löschen',
  change_sort_orientation: 'Anordnung ändern',
  enable_case_sensitivity: 'Grosschreibung',
  hint_autocomplete: 'Auswählen',
  enter_query_text: 'Tekst eingeben',
  email_label: 'Email',
  _label: 'label',
  home_label: 'Startseite',
  close_filter: 'Filter schliesen',
  open_filter: 'Filter öffnen',
  select_operator: 'Operator auswählen',
  not_match_found: 'Nichst gefunden',
  edit_user: 'Benutzer bearbeiten',
  firestore: 'Firestore',
  ads:'Kostenlose Kleinanzeigen',
  ads_description:'Erreichen sie mehr kunden! Verdienen mehr geld!',
  no_thanks: 'Nein danke!',
  post:'Anzeige aufgeben',
  user_label_search: 'Suchen',
  search_settings: 'Sucheinstellungen',
  found: 'Gefunden',
  girls_la: 'Damen in Ihrer Nähe',
  girl_la: 'Frau in Ihrer Nähe',
  join_us: 'Hier werben!',
  rates_label: 'Honorare',
  profiles_label: 'Benutzerprofile',
  favourites: 'Favoriten',
  available: 'Ist ab sofort verfügbar',
  undefined_inla: ' ',
  undefined_outla: ' ',
  al_la: 'Albanische',
  ar_la: 'Argentinische',
  dz_la: 'Algerische',
  am_la: 'Armenische',
  au_la: 'Australische',
  at_la: 'Österreichische',
  az_la: 'Aserbaidschanische',
  bs_la: 'Bosnische',
  by_la: 'Belarussische',
  be_la: 'Belgische',
  bo_la: 'Bolivianische',
  br_la: 'Brasilianische',
  bg_la: 'Bulgarische',
  cm_la: 'Kamerunische',
  cat_la: 'Katalanische',
  ca_la: 'Kanadische',
  cl_la: 'Chilenische',
  cn_la: 'Chinesische',
  co_la: 'Kolumbianische',
  cr_la: 'Costaricanische',
  hr_la: 'Kroatische',
  cu_la: 'Kubanische',
  cz_la: 'Tschechische',
  dk_la: 'Dänische',
  do_la: 'Dominikanische',
  ec_la: 'Ecuadorianische',
  eg_la: 'Ägyptische',
  ee_la: 'Estnische',
  fi_la: 'Finnische',
  fr_la: 'Französische',
  ge_la: 'Georgische',
  de_la: 'Deutsche',
  gr_la: 'Griechische',
  gb_la: 'Britische',
  en_la: 'Englische',
  sct_la: 'Schottische',
  ht_la: 'Haitianische',
  hn_la: 'Honduranische',
  hu_la: 'Ungarische',
  in_la: 'Indische',
  is_la: 'Isländische',
  ga_la: 'Irische',
  id_la: 'Indonesische',
  il_la: 'Israelische',
  it_la: 'Italienische',
  jm_la: 'Jamaikanische',
  jp_la: 'Japanische',
  kr_la: 'Koreanische',
  kz_la: 'Kazakhstan',
  lv_la: 'Lettische',
  lt_la: 'Litauische',
  my_la: 'Malaysische',
  mk_la: 'Mazedonische',
  mx_la: 'Mexikanische',
  md_la: 'Moldauische',
  ma_la: 'Marokkanische',
  nl_la: 'Niederländische',
  ni_la: 'Nicaraguanische',
  ng_la: 'Nigerianische',
  no_la: 'Norwegische',
  pk_la: 'Pakistanische',
  py_la: 'Paraguayische',
  fa_la: 'Persische',
  pl_la: 'Polnische',
  pt_la: 'Portugiesische',
  ro_la: 'Rumänische',
  ru_la: 'Russische',
  rs_la: 'Serbische',
  sk_la: 'Slowakische',
  si_la: 'Slowenische',
  es_la: 'Spanische',
  se_la: 'Schwedische',
  ch_la: 'Schweizer',
  th_la: 'Thailändische',
  tn_la: 'Tunesische',
  tr_la: 'Türkische',
  ua_la: 'Ukrainische',
  us_la: 'U. S. American',
  uz_la: 'Usbekische',
  cy_la: 'Walisische',
  ve_la: 'Venezolanische',
  vn_la: 'Vietnamesisch',
  orientation_label: 'Orientierung',
  bisexual_label: 'Bisexuell',
  straight_label: 'Hetero',
  bicurious_label: 'Bi-Neugierig',
  gallery_label:'Escort in Deiner Nähe',
  details_label: 'Über mich',
  details_la: 'Angaben',
  details: 'Standort aktualisieren',
  age_label: 'Alter',
  phone_label: 'Telefonnummer',
  phone_hint: 'Kontakttelefon',
  show_tel: 'Telefonnummer',
  website_label: 'Persönliche website',
  website_hint: 'd.h. www.meinewebseite.de',
  nationality_label: 'Nationalität',
  smoking_label: 'Raucher',
  languages_label:'Sprachen',
  dress: 'Kleidergröße',
  hair_label: 'Haarfarbe',
  breast_label: 'Brustgröße',
  bust_label: 'Brustgröße*',
  breast_hint: 'Geben Sie die BH-Größe ein',
  weight_label: 'Gewicht',
  height_label: 'Grösse',
  weight_hint: 'Geben Sie Ihr Gewicht ein',
  eyes_label:'Augenfarbe',
  piercings_label:'Körperschmuck',
  daddy_label: 'Sugar Baby Erwartungen',
  sugar_label: 'Ich will einen Sugar Daddy',
  sugar_hint: 'Erwartung (d.h 9000€ /monatlich)',
  service_label: 'Wählen Sie den Besprechungsort',
  onein_label: '1hour',
  onehour_label: 'Eine Stunde',
  overnight_label: 'Overnight',
  addhour_label: 'Extrastunden',
  threedays_label: '3 Tage',
  time_label: 'Dauer',
  week_label: 'Eine Woche',
  twentyfourhour_label: '24 Stunden',
  service: 'Verfügbarkeit',
  distance: 'Entfernung(km)',
  miles_away: ' km entfernt',
  edit_profile: 'Profil erstellen',
  blonde_label: 'Blond',
  gray_label: 'Grau',
  red_label: 'Rot',
  purple_label:'Purpur',
  brunette_label: 'Brünett',
  black_label: 'Schwarz',
  amber_label: 'Bernstein',
  blue_label: 'Blaue',
  green_label: 'Grüne',
  hazel_label: 'Hasel',
  brown_label: 'Braun',
  other_label: 'Andere',
  inout_label: 'Haus- & Hotelbesuch',
  true_inla: 'Besuchbar',
  true_outla: 'Ich besuche',
  outcall_label: 'Besuche',
  incall_label: 'Besuchbar',
  outcall_la: 'Ich besuche',
  incall_la: 'Besuchbar',
  false_outla: ' ',
  false_inla: ' ',
  info_label: 'Info',
  ios_prompt_title: 'Zum Home-Bildschirm hinzufügen',
  ios_prompt_close: 'Schließen',
  ios_prompt_body:
    'Escort⁺ verfügt über App-Funktionen. Fügen Sie diese zu Ihrem Startbildschirm hinzu, um sie im Vollbildmodus und offline zu verwenden.',
  ios_prompt_share_button: "1) Klicken Sie auf die Schaltfläche 'Teilen'",
  ios_prompt_add_to_home_button: "2) Klicken Sie auf 'Zum Home-Bildschirm'",

});

export default messages;
