const ActionTypes = {
    GET_ACTIVE_PROFILE: 'GET_ACTIVE_PROFILE',
    SET_ACTIVE_PROFILE: 'SET_ACTIVE_PROFILE',

    ADD_PROFILES: 'ADD_PROFILES',
    FILTER_PROFILES: 'FILTER_PROFILES',

    UPDATE_FILTERS: 'UPDATE_FILTERS',

    SET_USER_LOCATION: 'SET_USER_LOCATION'
}

export default ActionTypes;
