import ActionTypes from './ActionTypes';

const initialState = {
    profiles: [],
    filteredProfiles: [],
    activeProfile: null,
    userLocation: []
}

export default function escorts(state = initialState, action) {
    switch (action.type) {
      case ActionTypes.ADD_PROFILES:

        return {
          ...state,
            profiles: action.payload
        }
      case ActionTypes.FILTER_PROFILES:
        return {
          ...state,
          filteredProfiles: action.payload
        }

      case ActionTypes.SET_ACTIVE_PROFILE:



        return {
          ...state,
          activeProfile: action.profile
        }

      case ActionTypes.SET_USER_LOCATION:


        return {
          ...state,
          userLocation: [action.payload.coords.latitude, action.payload.coords.longitude]
        }
      default:
        return state
    }
  }
