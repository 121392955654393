import React from 'react'
import Loadable from 'react-loadable'
import getMenuItems from './menuItems'
import LoadingComponent from 'rmw-shell/lib/components/LoadingComponent'
import locales from './locales'
import routes from './routes'
import themes from './themes'
import grants from './grants'
import parseLanguages from 'rmw-shell/lib/utils/localeTools'

const Loading = () => <LoadingComponent />

const LPAsync = Loadable({
  loader: () => import('../../src/pages/LandingPage'),
  loading: Loading,
})

const config = {
  firebase_config: {
    apiKey: "AIzaSyDpS2NH2ZFG8HkI6ULc9Rdz5F4z3HAPGt0",
    authDomain: "escort-plus.firebaseapp.com",
    databaseURL: "https://escort-plus.firebaseio.com",
    projectId: "escort-plus",
    storageBucket: "gs://escort-plus",
    messagingSenderId: "736392647662",
    appId: "1:736392647662:web:4bb479bb982285d091b4ff"
   },
    firebase_config_dev: {
    apiKey: "AIzaSyDCp2XaZ1ugC2cr8wq0le166spOgFMildc",
    authDomain: "nearestescorts.firebaseapp.com",
    databaseURL: "https://nearestescorts.firebaseio.com",
    projectId: "nearestescorts",
    storageBucket: "gs://nearestescorts",
    messagingSenderId: "437411364372",
    appId: "1:437411364372:web:2431e7877e67095c"
  },
  firebase_providers: [
    'google.com',
    'twitter.com',
    'password',
    {
            provider: 'phone',
            defaultCountry: 'GB',
         }
  ],
  googleMaps: {
    apiKey: 'AIzaSyAtYfr7AM2xqcDY4FzHlqKx6Uw-XzzPdCk',
  },
  initial_state: {
    themeSource: {
      isNightModeOn: true,
      source: 'light',
    },
    locale: parseLanguages(['en', 'ru', 'es', 'ro', 'de'], 'en'),
  },
  drawer_width: 256,
  locales,
  themes,
  grants,
  routes,
  getMenuItems,
  firebaseLoad: () => import('./firebase'),
  landingPage: LPAsync,
}

export default config
