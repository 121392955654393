import ActionTypes from './ActionTypes';

const initialState = {
    profileCost: 'all',
    profileDistance: 2
}

export default function profiles(state = initialState, action) {
    switch (action.type) {
      case ActionTypes.UPDATE_FILTERS:
        
        return {
          ...state,
          ...action.payload
        }
      default:
        return state
    }
  }
